import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Back from '../components/back';

class VideosIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulVideoLink.nodes');


    return (
      <Layout >
        <div style={{ background: '#fff' }}>
          <Helmet title="Dana Royal's Videos and Motion Graphics" />
     
          <Back/>
          <div>
            <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center', gap: '3rem'}}>
            {
             posts.map((post) => {
                return (



                  
<div>
  {
    post.url.includes('https://vimeo.com/') ?
    <div style={{ position: 'relative', height: '50vh', width: '98vw'}}>
    <iframe title={post.title} src={`https://player.vimeo.com/video/${post.url.replace('https://vimeo.com/', '')}?h=b2cff00177&byline=0`}  style={{ maxWidth: '100vw', position: 'absolute', right: 0, left: 0, width: '100%', height: '100%'}} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
    </div> :
      <div style={{fontSize: '2rem', textAlign: 'center', margin: '2rem'}}><a href={post.url}>{post.title} </a></div>
  }
 </div>
                   
                )
              })
            }
      
            </div>
            
            <section style={{clear: 'both', padding: '3.75rem', textAlign: 'center'}}>
              <a href="#">
                <span style={{display: 'inline-block', marginRight: '10px'}}>↑</span>
                <span>Back to Top</span>
              </a>
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

export default VideosIndex



export const pageQuery = graphql`
  query VideoQuery {
    allContentfulVideoLink {
      nodes {
        title
        url
      }
      }
    }
`
//  <iframe title={post.title} style={{maxWidth: '100vw'}} src={`https://player.vimeo.com/video/${post.url.replace('https://vimeo.com/', '')}?h=b2cff00177`} width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>